import {Fragment} from 'react';
import ReactMarkdown from 'react-markdown/with-html';

import {buildURL} from 'utils/url';
import {interpolate} from 'utils/i18n';
import language from 'utils/language';

import Query from 'components/Query';

const SnippetCatalog = window.SnippetCatalog || {};

export const SnippetQuery = ({queryKey, initialData, context, children, ...props}) => (
    <Query queryKey={queryKey} initialData={initialData}>
        {content => {
            const source = context ? interpolate(content, context) : content;

            return (
                <Fragment>
                    <ReactMarkdown {...props} source={source} />
                    {children && children(source)}
                </Fragment>
            );
        }}
    </Query>
);

const RemoteSnippet = ({identifier, context, children, ...props}) => {
    const queryKey = buildURL(language, 'snippet', identifier);
    const initialData = SnippetCatalog[identifier];

    return (
        <SnippetQuery queryKey={queryKey} initialData={initialData} context={context} {...props}>
            {children}
        </SnippetQuery>
    );
};

export default RemoteSnippet;

import {matchPath} from 'react-router';

const German = 'de';
const English = 'en';
const Spanish = 'es';
const French = 'fr';
const Italian = 'it';
const Dutch = 'nl';
const Danish = 'da';

export const Language = {
    German,
    English,
    Spanish,
    French,
    Italian,
    Dutch,
    Danish,
};

const Languages = [
    German,
    English,
    Spanish,
    French,
    Italian,
    Dutch,
    Danish,
];

export const DefaultLanguage = English;

export const getLanguage = pathname => {
    const match = matchPath(pathname, '/:language/');

    if (match) {
        const {language} = match.params;

        if (Languages.includes(language)) return language;
    }

    return DefaultLanguage;
};

export default getLanguage(window.location.pathname);

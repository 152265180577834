import {createBrowserHistory as createHistory} from 'history';

import {scrollToTop} from './scroll';

const scrollToTopAction = (action, path, state, scroll = true) => {
    action(path, state);
    if (scroll) scrollToTop('auto');
};

export const createBrowserHistory = options => {
    const {push, replace, ...history} = createHistory(options);

    history.push = (...args) => scrollToTopAction(push, ...args);
    history.replace = (...args) => scrollToTopAction(replace, ...args);

    return history;
};

import {createPortal} from 'react-dom';
import cN from 'classnames';

const container = document.getElementById('overlay-container');

const Overlay = ({children, className, ...props}) => createPortal(
    <div className={cN('overlay', className)} {...props}>{children}</div>,
    container,
);

export default Overlay;

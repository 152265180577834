import {getJSON, setJSON} from './localStorage';
import {keys} from './object';

const Key = 'custom-user-locations';

const getLocations = () => getJSON(Key, {});

const getQueryKey = ({latitude, longitude}) => ['city', latitude, longitude];

const setQueryData = (queryClient, location) => {
    const queryKey = getQueryKey(location);

    queryClient.setQueryData(queryKey, location);
    queryClient.setQueryDefaults(queryKey, {
        staleTime: Infinity,
        cacheTime: Infinity,
    });
};

export const addLocation = (queryClient, location) => {
    const locations = getLocations();
    const key = getQueryKey(location).join('-');

    locations[key] = location;

    setQueryData(queryClient, location);
    setJSON(Key, locations);
};

export const primeQueryClient = queryClient => {
    const locations = getLocations();

    keys(locations).forEach(key => {
        const location = locations[key];

        setQueryData(queryClient, location);
    });
};

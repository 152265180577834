import _ from 'utils/i18n';
import {scrollToTop} from 'utils/scroll';

const Label = _('To Top');

const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();

    scrollToTop('smooth');
};

const ToTopLink = () => (
    <div className="to-top">
        <a href="#" onClick={handleClick}>
            <span className="txt">{Label}</span>
        </a>
    </div>
);

export default ToTopLink;

import {useEffect, useState} from 'react';
import classNames from 'classnames';

import Renderers from 'components/Renderers';
import RemoteSnippet from 'components/RemoteSnippet';

const BaseBanner = ({className, identifier, context, escapeHtml, delay, children}) => {
    const [opacity, setOpacity] = useState(delay ? 0 : 1);

    useEffect(
        () => {
            if (!delay) return;

            const timer = setTimeout(() => setOpacity(1), delay);

            return () => clearTimeout(timer);
        },
        [setOpacity, delay],
    );

    return (
        <div className={classNames('cookie-banner', className)} style={{opacity}} data-nosnippet="true">
            <div className="text">
                <RemoteSnippet
                    identifier={identifier}
                    context={context}
                    escapeHtml={escapeHtml}
                    renderers={Renderers}
                />
            </div>
            <div className="buttons">
                {children}
            </div>
        </div>
    );
};

BaseBanner.defaultProps = {
    delay: 0,
    escapeHtml: false,
};

export default BaseBanner;

import {TextContent} from 'components/Content';

import Copyright from './Copyright';
import ToTopLink from './ToTopLink';

const BaseFooter = ({hideCopyright, children}) => (
    <footer className="container footer">
        <div className="section">
            <div className="main-content">
                <ToTopLink />
                <div className="menu">
                    {children}
                </div>
            </div>
            <TextContent className="has-text-centered">
                {hideCopyright ? <p>&nbsp;</p> : <Copyright />}
            </TextContent>
        </div>
    </footer>
);

BaseFooter.defaultProps = {
    hideCopyright: false,
};

export default BaseFooter;

export class NamedError extends Error {

    static name = 'Error';

    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }

}

import {useLanguage} from 'selectra/hooks';

import BaseList from 'components/BaseList';

import LanguageEntry from './LanguageEntry';

const getCode = ({code}) => code;

const LanguageList = () => {
    const {current, languages, urls, switchLanguage} = useLanguage();
    const getProps = ({code}) => ({current: code === current, available: urls === null || urls[code]});

    return (
        <BaseList type="language" entries={languages} getKey={getCode} getProps={getProps}>
            <LanguageEntry switchLanguage={switchLanguage} />
        </BaseList>
    );
};

export default LanguageList;

import IconDown from 'images/icon-arrow-down.svg.react';

import {useCallback} from 'react';

import {useLanguage} from 'selectra/hooks';

const LanguageSwitcher = () => {
    const {current, languages, urls, switchLanguage} = useLanguage();
    const handleSelect = useCallback(({target}) => switchLanguage(target.value), [switchLanguage]);

    return (
        <div className="language-switcher">
            <select value={current} onChange={handleSelect}>
                {languages.map(({code, label}) => (
                    (current === code || urls === null || urls[code]) && (
                        <option key={code} value={code}>{label}</option>
                    )
                ))}
            </select>
            <IconDown />
        </div>
    );
};

export default LanguageSwitcher;

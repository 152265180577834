import {buildPath} from 'utils/url';

import SimpleRoute from 'components/SimpleRoute';
import SwitchOrNotFound from 'components/SwitchOrNotFound';
import LoginRequiredRoute from 'components/LoginRequiredRoute';

export const url = (path, page, options = {}) => ({...options, path, page});
export const include = (path, subRoutes) => url(path, null, {include: subRoutes});

const Include = ({path: parentPath, subRoutes, render}) => (
    <SwitchOrNotFound>
        {subRoutes.map(route => render({...route, parentPath}))}
    </SwitchOrNotFound>
);

const renderRoute = ({page, path, parentPath, include, auth = false, exact = true, ...options}) => {
    const Route = auth ? LoginRequiredRoute : SimpleRoute;
    const Child = include ? Include : page;
    const absolutePath = path.map ? path.map(path => buildPath(parentPath, path)) : buildPath(parentPath, path);
    const key = absolutePath.join ? absolutePath.join('#') : absolutePath;

    return (
        <Route key={key} {...options} path={absolutePath} exact={include ? false : exact}>
            {({url, path, params}) => (
                <Child
                    url={url}
                    path={path}
                    {...params}
                    render={renderRoute}
                    subRoutes={include}
                />
            )}
        </Route>
    );
};

const URLConfig = ({routes, basePath = null}) => <Include path={basePath} subRoutes={routes} render={renderRoute} />;

export default URLConfig;

import Languages from 'schemes/languages.json';

import {useCallback} from 'react';

import language from 'utils/language';
import {Slash} from 'utils/url';

import {useHRefLang} from './hreflang';

export const useLanguage = () => {
    const urls = useHRefLang();

    const switchLanguage = useCallback(
        code => {
            let href = window.location.pathname;

            if (urls) {
                const {url} = urls[code];

                href = url;
            } else {
                const bits = href.split(Slash);

                bits[1] = code;

                href = bits.join(Slash);
            }

            if (href) window.location.href = href;
        },
        [urls],
    );

    return {current: language, languages: Languages, urls, switchLanguage};
};

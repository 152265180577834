import {TextContent} from 'components/Content';

const ShowError = process.env.NODE_ENV !== 'production';

const BaseError = ({headline, message, error, className}) => (
    <TextContent className={['error', className]}>
        <h4>{headline}</h4>
        <p>{message}</p>
        {ShowError && error && <pre>{String(error)}</pre>}
    </TextContent>
);

export default BaseError;

import {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import getInitialState, {getIDs} from 'utils/favorites';
import {setJSON} from 'utils/localStorage';
import {Settings, useSetting} from 'selectra/settings';

const FavoritesContext = createContext(null);

export const useIsFavorite = id => {
    const {favorites} = useContext(FavoritesContext);

    return favorites[id] || false;
};

export const useToggleFavorite = () => {
    const {setFavorites} = useContext(FavoritesContext);

    return useCallback(
        id => {
            setFavorites(favorites => ({
                ...favorites,
                [id]: !favorites[id],
            }));
        },
        [setFavorites],
    );
};

export const useFavorites = () => {
    const {favorites} = useContext(FavoritesContext);

    return useMemo(() => getIDs(favorites), [favorites]);
};

const useInitialValue = () => {
    const brand = useSetting(Settings.brand);
    const optician = useSetting(Settings.optician);

    return useMemo(() => getInitialState({brand, optician}), [brand, optician]);
};

const usePersistFavorites = (favorites, localStorageKey) => {
    const changeCount = useRef(0);

    useEffect(
        () => {
            if (changeCount.current++ === 0) return;

            const ids = getIDs(favorites);

            setJSON(localStorageKey, ids);
        },
        [favorites, localStorageKey, changeCount],
    );
};

const FavoritesProvider = ({children}) => {
    const [localStorageKey, initialValue] = useInitialValue();
    const [favorites, setFavorites] = useState(initialValue);
    const value = {favorites, setFavorites};

    usePersistFavorites(favorites, localStorageKey);

    return (
        <FavoritesContext.Provider value={value}>
            {children}
        </FavoritesContext.Provider>
    );
};

export default FavoritesProvider;

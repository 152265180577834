import {render} from 'react-dom';

import {captureException} from 'utils/sentry';
import addUnsupportedBrowserMessage from 'utils/unsupported-browser';

export default (App, props = {}, selector = 'app') => {
    const RootNode = document.getElementById(selector);

    try {
        return render(<App {...props} />, RootNode);
    } catch (error) {
        captureException(error);
        addUnsupportedBrowserMessage(RootNode);
    }
};

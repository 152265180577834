var React = require('react');

function IconCloseSvg (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M84.36.353l-84 85.375M.36.353l84 85.375"}));
}

IconCloseSvg.defaultProps = {"className":"icon close","viewBox":"0 0 84.717511 86.0808"};

module.exports = IconCloseSvg;

IconCloseSvg.default = IconCloseSvg;

import {useHasFinishedInitialFetching} from 'selectra/hooks/react-query';

const Delay = ({prefetch, children}) => {
    const hasFinishedInitialFetching = useHasFinishedInitialFetching();

    if (!prefetch) return hasFinishedInitialFetching && children;

    return (
        <div style={{display: hasFinishedInitialFetching ? 'initial' : 'none'}}>
            {children}
        </div>
    );
};

export default Delay;

import _ from 'utils/i18n';

import {Maintenance as Message} from 'selectra/messages';

import BaseError from './BaseError';

const Headline = _('Maintenance');

const Maintenance = () => (
    <BaseError className="maintenance" headline={Headline} message={Message} />
);

export default Maintenance;

import {useCallback} from 'react';

import _ from 'utils/i18n';

import {TextContent} from 'components/Content';

const ErrorText = _('Oh Snap. An unexpected error has occurred.');
const ReloadText = _('Reload the page and try again.');

const ReloadButton = () => {
    const handleClick = useCallback(() => window.location.reload(), []);

    return <button onClick={handleClick}>{ReloadText}</button>;
};

const PageErrorMessage = () => (
    <TextContent>
        <p className="error">{ErrorText}</p>
        <ReloadButton />
    </TextContent>
);

export default PageErrorMessage;

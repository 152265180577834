import _, {interpolate} from 'utils/i18n';

const {DefaultErrorsHint} = global;

export const ClientError = DefaultErrorsHint;
export const NetworkError = _('A network error occurred. Please try again.');
export const ServerError = _('A server error occurred. Please try again.');
export const Maintenance = _('Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.');

const Generic = _('An error occurred: %(error)s');

export const getGenericErrorMessage = error => interpolate(Generic, {error});

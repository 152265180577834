import classNames from 'classnames';

const MenuItem = ({Element, className, ...props}) => (
    <Element className={classNames('menu-item', className)} {...props} />
);

MenuItem.defaultProps = {
    Element: 'a',
    className: null,
};

export default MenuItem;

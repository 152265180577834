import _ from 'utils/i18n';

import BaseError from './BaseError';

const Headline = _('Network Error');
export const Message = _('A network error occurred. Please check your internet connection.');

const NetworkError = props => (
    <BaseError {...props} className="network-error" headline={Headline} message={Message} />
);

export default NetworkError;

import _ from 'utils/i18n';

import {useURL} from 'selectra/hooks';

import MenuLink from './MenuLink';

const Label = _('Privacy');

const Privacy = () => (
    <MenuLink to={useURL('privacy-policy')}>
        {Label}
    </MenuLink>
);

export default Privacy;

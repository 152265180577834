import {Children, cloneElement, forwardRef} from 'react';
import classNames from 'classnames';

export const EntryKey = 'ListEntryTemplate';

const isEntry = child => {
    try {
        return child.key === EntryKey;
    } catch (error) {
        return false;
    }
};

const getDefaultKey = ({id}) => id;

const getDefaultProps = () => ({});

const renderEntries = (element, type, entries, getKey, getProps) => {
    const {className: providedClassName} = element.props;
    const className = classNames(providedClassName, type && `${type}-entry`);

    return entries.map((entry, index) => (
        cloneElement(element, {
            key: getKey(entry, index),
            ...entry,
            className,
            index,
            ...getProps(entry, index, element.props),
        })
    ));
};

const BaseList = props => {
    const {
        type,
        className,
        Element = 'ul',
        entries,
        getKey = getDefaultKey,
        getProps = getDefaultProps,
        children,
        elementRef,
        ...listProps
    } = props;

    const renderListEntries = element => renderEntries(element, type, entries, getKey, getProps);

    return (
        <Element className={classNames(className, type && `${type}-list`)} {...listProps} ref={elementRef}>
            {(Children.count(children) === 1
                ? renderListEntries(children)
                : Children.map(children, child => isEntry(child) ? renderListEntries(child) : child)
            )}
        </Element>
    );
};

export default forwardRef((props, ref) => <BaseList {...props} elementRef={ref} />);

import {useMemo} from 'react';
import {useHistory} from 'react-router';

import {buildURL, clean} from 'utils/url';

export const URLBuilder = document.createElement('a');

export const useAbsoluteURL = (...path) => {
    const history = useHistory();
    const pathname = buildURL(...path);

    URLBuilder.href = history.createHref({pathname});

    return URLBuilder.href;
};

export const useURL = path => useMemo(() => buildURL(clean(path)), [path]);

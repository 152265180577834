/* global FAVRHost */

import trim from 'lodash/trim';

import {At, Brands, In, Opticians} from 'selectra/urls';

import language from './language';
import {keys} from './object';

export const Slash = '/';

export const clean = url => trim(url, Slash);

export const buildURL = (...args) => ['', ...args.map(clean).filter(Boolean), ''].join(Slash);

export const buildPath = buildURL;

const buildRelativeAPIURL = (name, id = null, action = null) => buildURL(language, 'api', name, id, action);

const buildAbsoluteAPIURL = (name, id = null, action = null) => String(
    new URL(buildRelativeAPIURL(name, id, action), FAVRHost),
);

export const buildAPIURL = FAVRHost ? buildAbsoluteAPIURL : buildRelativeAPIURL;

export const withParams = (url, params) => {
    const urlSearchParams = new URLSearchParams();

    keys(params).forEach(key => {
        const value = params[key];

        if (value instanceof Array) {
            value.forEach(value => urlSearchParams.append(key, value));
        } else {
            urlSearchParams.append(key, value);
        }
    });

    return `${url}?${urlSearchParams.toString()}`;
};

export const buildStoreListURL = (city, brand, ...path) => {
    const components = [Opticians];

    if (city) {
        const {is_city: isCity = true} = city;

        if (isCity) components.push(In, city.slug, city.id);
        else components.push(At, city.latitude, city.longitude);
    }

    if (brand) components.splice(0, 0, Brands, brand.slug, brand.id);

    return buildURL(...components, ...path);
};

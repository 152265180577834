import {useCallback} from 'react';
import {atom, useAtomValue, useSetAtom} from 'jotai';

import {isString} from 'utils/object';

const messagesAtom = atom([]);

export const Intent = {
    Info: 'info',
    Success: 'success',
    Warning: 'warning',
    Error: 'error',
};

let MessageID = 0;

const DefaultMessageTimeout = 5000;

export const useAddMessage = () => {
    const setMessages = useSetAtom(messagesAtom);

    return useCallback(
        (content, timeout = null) => {
            const id = ++MessageID;

            let message = {id, timeout};

            if (isString(content)) {
                message.content = content;
            } else {
                message = {...message, ...content};
            }

            if (message.timeout === true) message.timeout = DefaultMessageTimeout;

            setMessages(messages => [...messages, message]);

            return id;
        },
        [setMessages],
    );
};

export const useRemoveMessage = () => {
    const setMessages = useSetAtom(messagesAtom);

    return useCallback(
        id => {
            setMessages(messages => messages.filter(message => message.id !== id));
        },
        [setMessages],
    );
};

export const useMessages = () => useAtomValue(messagesAtom);

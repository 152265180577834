import {getJSON} from './localStorage';
import {keys} from './object';

export const getIDs = favorites => keys(favorites).filter(id => favorites[id] || false).map(id => +id);

const DefaultLocalStorageKey = 'product.favorite';

const getLocalStorageKey = ({brand, optician}) => {
    if (brand) return `brand-${brand}-${DefaultLocalStorageKey}`;
    if (optician) return `${optician}-${DefaultLocalStorageKey}`;

    return DefaultLocalStorageKey;
};

export default config => {
    const localStorageKey = getLocalStorageKey(config);
    const ids = getJSON(localStorageKey);
    const initialState = {};

    if (ids) ids.forEach(id => { initialState[id] = true; });

    return [localStorageKey, initialState];
};

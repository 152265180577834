import _ from 'utils/i18n';

import BaseError from './BaseError';

const Headline = _('404 - 🦄 Not Found');
const Message = _('Sorry, the requested content could not be found. Maybe it has moved or you followed an invalid link.');

const NotFound = props => <BaseError {...props} className={404} />;

NotFound.defaultProps = {
    headline: Headline,
    message: Message,
};

export default NotFound;

import {Redirect} from 'react-router';

import {buildURL} from 'utils/url';

import UserQuery from 'components/UserQuery';

import Spinner from './Spinner';
import SimpleRoute from './SimpleRoute';

const LoginURL = buildURL('login');

const LoginRequiredRoute = ({children, Loading = Spinner, ...props}) => (
    <UserQuery components={{loading: Loading}}>
        {({is_authenticated: isAuthenticated}) => (
            <SimpleRoute {...props}>
                {isAuthenticated ? children : ({url}) => <Redirect to={`${LoginURL}?next=${url}`} />}
            </SimpleRoute>
        )}
    </UserQuery>
);

export default LoginRequiredRoute;

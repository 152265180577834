import {Helmet} from 'react-helmet';

import {entries} from 'utils/object';

import {useHRefLang} from 'selectra/hooks/hreflang';

const AlternativeLanguages = () => {
    const urls = useHRefLang();

    if (urls === null) return null;

    return (
        <Helmet>
            {entries(urls).filter(([, {url, index}]) => url && index).map(([code, {url}]) => (
                <link key={code} rel="alternate" hrefLang={code} href={url} />
            ))}
        </Helmet>
    );
};

export default AlternativeLanguages;

import {QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import {SettingsProvider} from 'selectra/settings';

import FavoritesProvider from 'components/FavoritesContext';
import SingletonProvider from 'components/MicroSite/Singleton';
import ProductTypeProvider from 'components/ProductType';
import ProductURLProvider, {buildProductURL} from 'components/ProductURLContext';
import InitialPageLoadProvider from 'components/InitialPageLoad';

const Providers = ({queryClient, children, ...config}) => {
    const {
        settings = {},
        singleton = null,
        defaultProductType = null,
        getProductURL = buildProductURL,
    } = config;

    return (
        <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'production' || <ReactQueryDevtools />}
            <SettingsProvider value={settings}>
                <ProductTypeProvider initialValue={defaultProductType}>
                    <ProductURLProvider value={getProductURL}>
                        <SingletonProvider value={singleton}>
                            <FavoritesProvider>
                                <InitialPageLoadProvider>
                                    {children}
                                </InitialPageLoadProvider>
                            </FavoritesProvider>
                        </SingletonProvider>
                    </ProductURLProvider>
                </ProductTypeProvider>
            </SettingsProvider>
        </QueryClientProvider>
    );
};

export default Providers;

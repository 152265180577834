import _ from 'utils/i18n';

const Label = _('Premium Eyewear Finder');

const EyewearFinder = () => (
    <div className="eyewearfinder">
        <span className="label">{Label}</span>
    </div>
);

export default EyewearFinder;

import Delay from 'components/Delay';

import Footer from './BaseFooter';
import MenuItem from './MenuItem';
import MenuLink from './MenuLink';
import Privacy from './Privacy';
import Imprint from './Imprint';

export {MenuItem, MenuLink, Privacy, Imprint};

const BaseFooterController = props => (
    <Delay>
        <Footer {...props} />
    </Delay>
);

export default BaseFooterController;

import _ from 'utils/i18n';

import BaseError from './BaseError';

const Headline = _('Error');

const GenericError = ({error}) => (
    <BaseError className="generic-error" headline={Headline} message={`${error}`} error={error} />
);

export default GenericError;

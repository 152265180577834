import {QueryClient} from 'react-query';

import fetch, {NetworkError} from 'utils/fetch';
import {buildAPIURL, Slash, withParams} from 'utils/url';
import {isObject} from 'utils/object';

const isURL = value => value.indexOf(Slash) >= 0;

const getURL = (queryKey, pageParam) => {
    if (pageParam) return pageParam;

    const lastIndex = queryKey.length - 1;
    const lastPart = queryKey[lastIndex];
    const [firstPart] = queryKey;

    let parts = queryKey;
    let params = null;

    if (isObject(lastPart)) {
        parts = queryKey.slice(0, lastIndex);
        params = lastPart;
    }

    let baseURL;

    if (parts.length === 1 && isURL(firstPart)) {
        baseURL = firstPart;
    } else {
        baseURL = buildAPIURL(...parts);
    }

    return params ? withParams(baseURL, params) : baseURL;
};

const buildRequest = url => {
    const requestOptions = {credentials: 'same-origin'};

    let controller = null;

    if (global.AbortController) {
        controller = new AbortController();
        requestOptions.signal = controller.signal;
    }

    const request = fetch(url, requestOptions);

    if (controller) request.cancel = () => controller.abort();

    return request;
};

export const defaultQueryFn = ({queryKey, pageParam}) => {
    const url = getURL(queryKey, pageParam);

    return buildRequest(url);
};

export const defaultRetry = (failureCount, error) => error instanceof NetworkError && failureCount < 5;

export default new QueryClient({
    defaultOptions: {
        queries: {
            retry: defaultRetry,
            queryFn: defaultQueryFn,
        },
    },
});

import {createContext, useContext, useEffect, useState} from 'react';

import {withParams} from 'utils/url';

import {useHasFinishedInitialFetching} from 'selectra/hooks/react-query';

const GoogleReCaptchaContext = createContext({ready: false});

export const useGoogleReCaptchaReady = () => {
    const {ready, setSubscriberCount} = useContext(GoogleReCaptchaContext);

    useEffect(
        () => {
            setSubscriberCount(count => count + 1);

            return () => setSubscriberCount(count => count - 1);
        },
        [setSubscriberCount],
    );

    return ready;
};

const GoogleReCaptchaAPIURL = 'https://www.google.com/recaptcha/api.js';
const GoogleReCaptchaOnLibraryLoad = 'onGoogleReCaptchaLibraryLoad';

const GoogleReCaptchaLoader = ({setReady}) => {
    useEffect(
        () => {
            window[GoogleReCaptchaOnLibraryLoad] = () => setReady(true);

            const scriptNode = document.createElement('script');

            scriptNode.async = true;
            scriptNode.defer = true;
            scriptNode.src = withParams(GoogleReCaptchaAPIURL, {
                onload: GoogleReCaptchaOnLibraryLoad,
                render: 'explicit',
            });

            document.body.appendChild(scriptNode);
        },
        [setReady],
    );

    return null;
};

const GoogleReCaptchaController = ({children}) => {
    const [load, setLoad] = useState(false);
    const [ready, setReady] = useState(false);
    const [subscriberCount, setSubscriberCount] = useState(0);
    const hasFinishedInitialFetching = useHasFinishedInitialFetching();
    const hasSubscribers = subscriberCount > 0;

    useEffect(
        () => {
            if (load) return;
            if (hasSubscribers) return setLoad(true);
            if (hasFinishedInitialFetching) {
                const timer = setTimeout(() => setLoad(true), 3 * 1000);

                return () => clearTimeout(timer);
            }
        },
        [hasSubscribers, hasFinishedInitialFetching, load],
    );

    return (
        <GoogleReCaptchaContext.Provider value={{ready, setSubscriberCount}}>
            {load && <GoogleReCaptchaLoader setReady={setReady} />}
            {children}
        </GoogleReCaptchaContext.Provider>
    );
};

export default GoogleReCaptchaController;

import Messages from 'components/Messages';
import URLConfig from 'components/URLConfig';
import UserContextProvider from 'components/UserContext';

const Layout = ({Header, routes, children}) => (
    <UserContextProvider>
        <Header />
        <Messages />
        <main className="container">
            <div className="section">
                <div className="main-content">
                    <URLConfig routes={routes} />
                </div>
            </div>
        </main>
        {children}
    </UserContextProvider>
);

export default Layout;

import {Component} from 'react';

import {captureException} from 'utils/sentry';

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        if (error.name === 'ChunkLoadError') {
            setTimeout(() => window.location.reload(), 0);
        } else {
            captureException(error);
        }

        this.setState({error, info});
    }

    render() {
        const {children, ErrorComponent} = this.props;
        const {error, info} = this.state;

        if (error) return <ErrorComponent error={error} info={info} />;

        return children;
    }

}

export default ErrorBoundary;

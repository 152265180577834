import {createContext, useContext, useMemo} from 'react';

import {PricesInCage, Site} from './constants';

export const Settings = {
    robots: Symbol('robots'),
    appointmentToolURL: Symbol('appointment-tool-url'),
    showBrandButton: Symbol('show-brand-button'),
    showBuyOnlineButton: Symbol('show-buy-online-button'),
    showBuyProductButton: Symbol('show-buy-product-button'),
    showProductHostedBy: Symbol('show-product-hosted-by'),
    showSimilarProducts: Symbol('show-similar-products'),
    showBrandCount: Symbol('show-brand-count'),
    showBrandProductFilters: Symbol('show-brand-product-filters'),
    showStoreBrands: Symbol('show-store-brands'),
    showStoreContact: Symbol('show-store-contact'),
    showStoreLocator: Symbol('show-store-locator'),
    unifiedStoreLocator: Symbol('unified-store-locator'),
    showBrandWebsiteButton: Symbol('show-brand-website-button'),
    showRegisterYourStore: Symbol('show-register-your-store'),
    showTopCategories: Symbol('show-top-categories'),
    showTopBrands: Symbol('show-top-brands'),
    showPrices: Symbol('show-prices'),
    site: Symbol('site'),
    brand: Symbol('brand'),
    optician: Symbol('optician'),
};

const DefaultValues = {
    [Settings.robots]: null,
    [Settings.appointmentToolURL]: null,
    [Settings.showBrandButton]: true,
    [Settings.showBuyOnlineButton]: true,
    [Settings.showBuyProductButton]: false,
    [Settings.showProductHostedBy]: false,
    [Settings.showSimilarProducts]: true,
    [Settings.showBrandCount]: true,
    [Settings.showBrandProductFilters]: false,
    [Settings.showStoreBrands]: true,
    [Settings.showStoreContact]: true,
    [Settings.showStoreLocator]: true,
    [Settings.unifiedStoreLocator]: false,
    [Settings.showBrandWebsiteButton]: true,
    [Settings.showRegisterYourStore]: true,
    [Settings.showTopCategories]: true,
    [Settings.showTopBrands]: true,
    [Settings.showPrices]: PricesInCage.FAVR,
    [Settings.site]: Site.Main,
    [Settings.brand]: null,
    [Settings.optician]: null,
};

const SettingsContext = createContext(DefaultValues);

const {Provider: SettingsProvider} = SettingsContext;

export {SettingsProvider};

export const SettingOverride = ({setting, value, children}) => {
    const settings = useContext(SettingsContext);
    const override = useMemo(() => ({...settings, [setting]: value}), [setting, value, settings]);

    return (
        <SettingsProvider value={override}>
            {children}
        </SettingsProvider>
    );
};

export const useSetting = key => {
    const settings = useContext(SettingsContext);

    return key in settings ? settings[key] : DefaultValues[key];
};

import {useEffect, useState} from 'react';

const DefaultConfig = {};

export const useIntersectionObserver = (elementRef, config = DefaultConfig) => {
    const {threshold = 0, root = null, rootMargin = '0%'} = config;
    const [entry, setEntry] = useState(null);

    useEffect(() => {
        if (!window.IntersectionObserver) return;

        const node = elementRef.current;

        if (!node) return;

        const updateEntry = ([entry]) => setEntry(entry);
        const observerParams = {threshold, root, rootMargin};
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        return () => observer.disconnect();

    }, [elementRef, threshold, root, rootMargin]);

    return entry;
};

const EmptyBoundingClientRect = {};

export const useBoundingClientRect = selector => {
    const [boundingClientRect, setBoundingClientRect] = useState(EmptyBoundingClientRect);

    useEffect(
        () => {
            const node = document.querySelector(selector);

            setBoundingClientRect(node ? node.getBoundingClientRect() : EmptyBoundingClientRect);
        },
        [selector],
    );

    return boundingClientRect;
};

export const useBodyClass = className => {
    const {body} = document;

    return useEffect(
        () => {
            if (!className) return;

            body.classList.add(className);

            return () => body.classList.remove(className);
        },
        [className, body],
    );
};

export const useNavBarHeight = () => {
    const {height: navBarHeight = 0} = useBoundingClientRect('.navbar');

    return navBarHeight;
};

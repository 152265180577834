import {useQuery} from 'react-query';
import {useHistory, useLocation} from 'react-router';

import language from 'utils/language';
import {buildURL} from 'utils/url';

const Minute = 60 * 1000;

export const useHRefLang = () => {
    const history = useHistory();
    const location = useLocation();
    const url = history.createHref(location);
    const endpoint = buildURL(language, 'hreflang');
    const {data} = useQuery([endpoint, {url}], {
        staleTime: 10 * Minute,
        cacheTime: 30 * Minute,
        select({urls}) {
            return urls;
        },
    });

    return data || null;
};

export const useNoIndex = (defaultValue = false) => {
    const urls = useHRefLang();

    if (urls === null) return defaultValue;

    const {url, index} = urls[language];

    return (url === null || index === false) ? true : defaultValue;
};

import {createContext, useCallback, useContext, useRef} from 'react';
import {buildURL} from 'utils/url';

import {ProductType} from 'selectra/constants';
import {Brands, Eyeglasses, Sunglasses} from 'selectra/urls';

export const TypeSlugs = {
    [ProductType.Eyeglasses]: Eyeglasses,
    [ProductType.Sunglasses]: Sunglasses,
};

export const buildProductURL = (brand, product, variant) => {
    const path = [TypeSlugs[product.type], product.slug, product.id];

    if (brand) path.splice(0, 0, Brands, brand.slug, brand.id);
    if (variant) path.push(variant.slug, variant.id);

    return buildURL(...path);
};

const ProductURLContext = createContext(buildProductURL);

export const useProductURL = (brand, product, variant) => useContext(ProductURLContext)(brand, product, variant);

export const useBuildVariantURL = (brand, product) => {
    const buildProductURL = useContext(ProductURLContext);
    const args = useRef(null);

    args.current = [brand, product];

    return useCallback(variant => buildProductURL(...args.current, variant), [buildProductURL]);
};

export default ProductURLContext.Provider;

import Helmet from 'react-helmet';
import {Router} from 'react-router';

import {createBrowserHistory} from 'utils/history';
import queryClient from 'utils/react-query';
import {primeQueryClient} from 'utils/user-location';

import Providers from 'components/Providers';
import {Robots} from 'components/Meta';
import AlternativeLanguages from 'components/AlternativeLanguages';
import GoogleReCaptchaLoader from 'components/GoogleReCaptchaLoader';

primeQueryClient(queryClient);

const BaseSite = ({basename, titleTemplate, defaultTitle, Layout, routes, ...config}) => {
    const history = createBrowserHistory({basename});

    return (
        <Providers queryClient={queryClient} {...config}>
            <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
            <Router history={history}>
                <Robots />
                <AlternativeLanguages />
                <GoogleReCaptchaLoader>
                    <Layout routes={routes} />
                </GoogleReCaptchaLoader>
            </Router>
        </Providers>
    );
};

export default BaseSite;

import _ from 'utils/i18n';

const Message = _('Loading…');

const Loading = ({height = 'auto'}) => (
    <div className="section is-centered" style={{height}}>
        <div className="loading">
            {Message}
        </div>
    </div>
);

export default Loading;

var React = require('react');

function IconArrowDownSvg (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M83.825-.178L41.88 41.965.536-.174l-.714.7 42.054 42.86L84.533.53z"}));
}

IconArrowDownSvg.defaultProps = {"className":"icon arrow-down","viewBox":"0 0 84.355 43.032"};

module.exports = IconArrowDownSvg;

IconArrowDownSvg.default = IconArrowDownSvg;

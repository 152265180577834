import {useCallback} from 'react';
import classNames from 'classnames';

const getClassName = (className, current, available) => classNames(className, {current, available});

const LanguageEntry = ({className, code, label, current, available, switchLanguage}) => {
    const handleClick = useCallback(() => switchLanguage(code), [code, switchLanguage]);

    return (
        <li className={getClassName(className, current, available)} onClick={handleClick}>
            <span className="label" title={label}>{code}</span>
        </li>
    );
};

export default LanguageEntry;

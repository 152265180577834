import {Link} from 'react-router-dom';

const Protocols = [
    'http',
    'mailto',
    'tel',
];

const isExternal = href => Protocols.some(protocol => href.startsWith(protocol));

export default {
    link: ({node, href, children, ...props}) => (
        isExternal(href)
            ? <a {...props} href={href} target="_blank">{children}</a>
            : <Link {...props} to={href}>{children}</Link>
    ),
};

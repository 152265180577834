import {Route, Switch} from 'react-router';

import {DefaultComponents} from 'components/QueryResult';

const {404: NotFound} = DefaultComponents;

const SwitchOrNotFound = ({children}) => (
    <Switch>
        {children}
        <Route component={NotFound} />
    </Switch>
);

export default SwitchOrNotFound;

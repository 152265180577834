import Overlay from 'components/Overlay';

import MessageItem from './MessageItem';

const MessageList = ({messages}) => (
    <Overlay className="messages">
        <ul className="message-list">
            {messages.map(message => <MessageItem {...message} key={message.id} />)}
        </ul>
    </Overlay>
);

export default MessageList;

import {useChunkIsLoading} from 'components/InitialPageLoad';
import Spinner from 'components/Spinner';

const ChunkFallback = () => {
    useChunkIsLoading();

    return <Spinner />;
};

export default ChunkFallback;

import {useMessages} from 'selectra/hooks/messages';

import MessageList from './MessageList';

const Messages = () => {
    const messages = useMessages();

    return messages.length ? <MessageList messages={messages} /> : null;
};

export default Messages;

import {pgettext} from 'utils/i18n';

const Headline = pgettext('unsupported browser', 'Dear Visitor,');
const Message = pgettext('unsupported browser', 'your browser is too old to display our site.');

export default node => {
    const h1 = document.createElement('h1');
    const p = document.createElement('p');

    node.id = 'unsupported browser';
    node.className = 'text-content';
    h1.innerText = Headline;
    p.innerText = Message;

    node.append(h1);
    node.append(p);
};

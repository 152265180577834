/* eslint-disable no-console */
/* global Sentry */

export const captureMessage = (message, context) => {
    if (global.Sentry) Sentry.message(message, context);
    else console.log(message);
};

export const captureException = (exception, context) => {
    if (global.Sentry) Sentry.exception(exception, context);
    else console.error(exception);
};

import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useIsFetching} from 'react-query';

const ChunkLoadingContext = createContext(null);
const HasFinishedInitialFetchingContext = createContext(false);

export const useHasFinishedInitialFetching = () => useContext(HasFinishedInitialFetchingContext);

export const useChunkIsLoading = () => {
    const setNumberOfChunksLoading = useContext(ChunkLoadingContext);

    useEffect(
        () => {
            setNumberOfChunksLoading(count => count + 1);

            return () => {
                setNumberOfChunksLoading(count => count - 1);
            };
        },
        [setNumberOfChunksLoading],
    );
};

const setCallback = window.requestAnimationFrame || window.setTimeout;
const cancelCallback = window.cancelAnimationFrame || window.clearTimeout;

const InitialPageLoad = ({children}) => {
    const numberOfQueriesFetching = useIsFetching();
    const [numberOfChunksLoading, setNumberOfChunksLoading] = useState(0);
    const [hasFinishedInitialFetching, setHasFinishedInitialFetching] = useState(false);
    const isFetching = (numberOfQueriesFetching + numberOfChunksLoading) > 0;
    const hasStartedInitialFetching = useRef(isFetching);
    const callbackRef = useRef(0);

    useEffect(
        () => {
            if (hasFinishedInitialFetching) return;

            if (isFetching) {
                cancelCallback(callbackRef.current);
                hasStartedInitialFetching.current = true;
            } else if (hasStartedInitialFetching.current) {
                callbackRef.current = setCallback(() => setHasFinishedInitialFetching(true));
            }
        },
        [isFetching, hasFinishedInitialFetching],
    );

    return (
        <ChunkLoadingContext.Provider value={setNumberOfChunksLoading}>
            <HasFinishedInitialFetchingContext.Provider value={hasFinishedInitialFetching}>
                {children}
            </HasFinishedInitialFetchingContext.Provider>
        </ChunkLoadingContext.Provider>
    );
};

export default InitialPageLoad;

import IconClose from 'images/icon-close.svg.react';

import {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';

import {Intent, useRemoveMessage} from 'selectra/hooks/messages';

const MessageItem = ({id, content, timeout, intent}) => {
    const remove = useRemoveMessage();
    const handleClose = useCallback(() => remove(id), [id, remove]);

    useEffect(
        () => {
            if (!timeout) return;

            const timer = setTimeout(handleClose, timeout);

            return () => clearTimeout(timer);
        },
        [timeout, handleClose],
    );

    return (
        <li className={cN('message-item', intent)}>
            <span className="message-content">{content}</span>
            <a className="is-close" onClick={handleClose}>
                <IconClose />
            </a>
        </li>
    );
};

MessageItem.propTypes = {
    timeout: PropTypes.number,
    intent: PropTypes.oneOf(Object.values(Intent)),
};

MessageItem.defaultProps = {
    timeout: null,
    intent: Intent.Info,
};

export default MessageItem;

import _ from 'utils/i18n';

import BaseError from './BaseError';

const Headline = _('Server Error');
export const Message = _('A server error occurred. This may be a temporary issue.');

const ServerError = props => (
    <BaseError {...props} className="server-error" headline={Headline} message={Message} />
);

export default ServerError;
